import { combineReducers } from 'redux';
import explore, { name as exploreName } from './shared/redux/presentations';
import presentation, { name as presentationName } from './shared/redux/presentation';
import auth, { name as authName } from './shared/redux/auth';
import cms, { name as cmsName } from './shared/redux/cms';
import ui, { name as uiName } from './shared/redux/ui';

const reducer = combineReducers({
	[exploreName]: explore,
  [authName]: auth,
  [presentationName]: presentation,
  [uiName]: ui,
  [cmsName]: cms
});
const initialState = {
};

function rootReducer(state = initialState, action) {
	return reducer(state, action);
}
export default rootReducer;
