import React, { Component, Fragment } from "react";
import { translate } from "react-i18next";
import { PlayerContext } from 'components/player/index.jsx';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import Typography from '@material-ui/core/Typography';
import { WithDependency } from "../../shared/DependencyContext";
import { actions as presentationActions, selectors as presentationSelectors } from 'shared/redux/presentation';
import { actions as uiActions } from 'shared/redux/ui';
import VRModal from 'components/VRModal/';
import ConnectionStatusModal from './components/ConnectionStatusModal';
import './index.scss';
import Modal from 'components/modal/index.jsx';
import { NavBarContext, NavBarStyles } from 'components/navBar';
import Config from 'config';
import IconMicOff from '@material-ui/icons/MicOff';
import IconMic from '@material-ui/icons/Mic';
import IconVolumeUp from '@material-ui/icons/VolumeUp';
import IconVolumeOff from '@material-ui/icons/VolumeOff';
import IconButton from '@material-ui/core/IconButton';
import IconCallEnd from '@material-ui/icons/CallEnd';
import IconCall from '@material-ui/icons/Call';
import IconInfo from 'static/home/presentation/info-icon.svg';
import IconGyroscope from 'static/home/presentation/gyroscope.svg';
import IconGyroscopeOff from 'static/home/presentation/gyroscope-off.svg';
import IconSpaceplan from '../../components/icons/IconSpaceplan';
import ScenesNav from 'components/scenes-nav';
import IconFullscreen from 'static/home/presentation/fullscreen-icon.svg';
import PresentationDashboard from 'components/presentation-dashboard';
import IconLayers from 'static/home/presentation/layers-icon.svg';
import IconVr from 'static/home/presentation/vr-icon.svg';
import closeJivoChat from '../../shared/closeJivoChat';
import SpaceplansModal from 'components/spaceplansModal';
import Classnames from "classnames";

const Modals = {
  errorModal: 'errorModal',
  enterHashModal: 'enterHashModal',
  enterNameModal: 'enterNameModal',
};

class Viewing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullscreenActive: false,
      VRModalOpened: false,
      hashInput: '',
      hashInputInvalid: false,
      activeModal: null,
      hostConnected: false,
      audioConnected: false,
      localMute: false,
      remoteMute: false,
      initialized: false,
      clientNameSet: false,
      enterHashModalCharCounter: 0,
      spaceplansOpened: false,
      currentSpaceplan: 0,
      changeSceneWithPinEnabled: false,
      isGyroscopeEnabled: null,
      spaceplansKey: false,
      changeSceneWithScenesNavEnabled: false,
      presentationDashboardOpen: false,
      presentingMode: 'guide',
      connectionStatus: 'hostDisconnected',
      openConnectionStatusModal: false,
      changeSceneWithArrowsEnabled: false,
      presentationDashboardVisibe: false,
      vrAviable: false,
      presentationReloading: false,
      isMobile: false
    };
    setInterval(() => {
      if (window.hostConnectionStatus === 'clientConnected') {
        this.setState({
          presentationDashboardVisibe: true,
        });
      } else if (window.hostConnectionStatus === 'clientDisconnected') {
        this.setState({
          presentationDashboardVisibe: false,
        });
      }
    }, 1000);

    if (location.search.indexOf('app') > -1) {
      document.body.classList.add('ui-hidden');
    }

    this.props.updateUi({navBarVisible: false});
    this.props.updateUi({footerVisible: false});

    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.handleMicClick = this.handleMicClick.bind(this);
    this.handleVolumeClick = this.handleVolumeClick.bind(this);
    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.toggleVR = this.toggleVR.bind(this);
    this.toggleQRModal = this.toggleQRModal.bind(this);
    this.onHashSubmit = this.onHashSubmit.bind(this);
    this.onHashInputChange = this.onHashInputChange.bind(this);
    this.onNameSubmit = this.onNameSubmit.bind(this);
    this.onNameInputChange = this.onNameInputChange.bind(this);
    this.toggleSpaceplansModal = this.toggleSpaceplansModal.bind(this);
    this.setPlanIndex = this.setPlanIndex.bind(this);
    this.onChangeScene = this.onChangeScene.bind(this);
    this.enableGyroscope = this.enableGyroscope.bind(this);
    this.disableGyroscope = this.disableGyroscope.bind(this);
    this.toggleSpaceplansKey = this.toggleSpaceplansKey.bind(this);
    this.onTogglePresentationDashboard = this.onTogglePresentationDashboard.bind(this);
    this.detectMobile = this.detectMobile.bind(this);
    this.isVRAviable = this.isVRAviable.bind(this);
    this.timeoutId = null;

    this.player = null; // player instance
    this.viewingClient = new evr.Viewing.Client({
      player: this.props.player.instance.instance,
      debug: true,
    });
    this.viewingClient.setConfig({
      socketHost: Config.socketServer.host,
    });
    this.viewingClient.on('hostConnected', () => {
      this.toggleModal(null);
      this.setState({
        hostConnected: true,
        connectionStatus: 'hostConnected',
        openConnectionStatusModal: true,
      });
    });

    this.viewingClient.on('clientName', name => {
      console.log('name',name);
    });
    this.viewingClient.on('hostDisconnected', () => {
      this.props.notifications.add(this.props.t('viewing.messages.hostDisconnected'));
      this.setState({
        hostConnected: false,
        connectionStatus: 'hostDisconnected',
        openConnectionStatusModal: true,
      });
    });
    this.viewingClient.on('audioConnected', () => {
      this.props.notifications.add(this.props.t('viewing.messages.voiceCallStarted'));
      console.log('Direct audio connected');
      this.setState({
        audioConnected: true,
        connectionStatus: 'audioConnected',
        openConnectionStatusModal: false,
      });
    });
    this.viewingClient.on('presentationChanged', name => {
      this.setState({ presentationReloading: true });
      const { viewing, fetchViewing } = this.props;
      fetchViewing({hash: viewing.hash});

      setTimeout(() => {
        const { presentation } = this.props;
        this.player.load(presentation);
        this.setState({ presentationReloading: false });
      }, 3000);
    });
    this.viewingClient.on('audioDisconnected', () => {
      this.props.notifications.add(this.props.t('viewing.messages.voiceCallCompleted'));
      console.log('Direct audio disconnected');
      this.setState({
        audioConnected: false,
        connectionStatus: 'audioDisconnected',
        openConnectionStatusModal: false,
      });
    });
    this.viewingClient.on('micAccessFailed', error => {
      this.setState({
        connectionStatus: 'micAccessFailed',
        openConnectionStatusModal: true,
      });
      this.props.notifications.add(this.props.t('viewing.messages.micAccessFailed'));
      console.log('Mic access failed', error);
    });
    this.viewingClient.on('peerMicMuted', muted => {
      console.log('Client mic mute state:', muted);
    });
    this.viewingClient.on('modeChanged', mode => {
      this.setState({ presentingMode: mode });
      if (mode === 'guide') {
        this.setState({
          changeSceneWithPinEnabled: false,
          changeSceneWithArrowsEnabled: true,
          changeSceneWithScenesNavEnabled: true,
        })
      } else (
        this.setState({
          changeSceneWithPinEnabled: true,
          changeSceneWithArrowsEnabled: false,
          changeSceneWithScenesNavEnabled: false,
        })
      )
    });

    window.addEventListener("fullscreenchange", this.onFullscreenChange);
    window.addEventListener("mozfullscreenchange", this.onFullscreenChange);
    window.addEventListener("webkitfullscreenchange", this.onFullscreenChange);

    if (this.props.match.params.hash) {
      this.state.hashInput = this.props.match.params.hash;
      this.props.fetchViewing({hash: this.props.match.params.hash});
    }
  }
  _initViewing(hash, presentation) {
    if (!this.viewingClient) return;

    if (presentation) {
      this.player.disableRotateScene();
      this.player.load(presentation);
    }

    this.viewingClient.connect({hash});
  }
  componentDidMount() {
    closeJivoChat();

    this.setState({ isMobile: this.detectMobile() });

    this.props.player.instance.getInstance().on('changeScene', this.onChangeScene);
    if (this.props.navBar) {
      this.props.navBar.hide();
    }
    if (this.player) {
      this.player.resize();

      if (this.props.viewing.hash && this.props.presentation) {
        this._initViewing(this.props.viewing.hash, this.props.presentation);
      }
      if (this.props.isFound && !this.props.isLoading) {
        this.player.load(this.props.presentation);
      }
      this.isVRAviable();
    }
  }
  componentWillReceiveProps(nextProps) {
    const { navBar } = this.props;

    navBar.setStyle(NavBarStyles.minimalPlayer);
    navBar.hidePageLinks();
    this.props.navBar.show();
    console.log('nextProps',nextProps.viewing);
    if (nextProps.viewing.creator && nextProps.viewing.creator.brand) {
      window.brand = true;
    }
    let nextViewing = nextProps.viewing,
      nextViewingHash = nextViewing && nextProps.viewing.hash,
      viewingFound = nextViewing && nextProps.viewing.isFound,
      viewingLoading = nextViewing && nextProps.viewing.isLoading,
      wasLoading = this.props.viewing.isLoading;

    if (!this.player && nextProps.player.instance) {
      this.player = nextProps.player.instance;
    }

    // In case there is no viewing in state, viewing should be created by fetchViewing in other methods
    if (!nextViewing) return;

    // Viewing hash is not set yet - open enter hash modal
    if (!nextViewingHash && this.state.activeModal === null && !this.state.presentationReloading) {
      this.setState({
        activeModal: Modals.enterHashModal,
      });
    }

    // Viewing hash has changed
    if (viewingFound && !viewingLoading) {
      if (nextViewingHash === undefined) {
        this.player.unload();
      } else {
        this.setState({
          activeModal: null,
        });
      }
    }

    // Viewing finished loading
    if (!viewingLoading && !this.state.presentationReloading) {
      if (viewingFound && !this.state.nameInput) {
        this.setState({
          activeModal: Modals.enterNameModal,
        });
      } else {
        if (wasLoading) {
          // this.setState({
          //   activeModal: Modals.errorModal,
          // });
        } else if (!this.state.nameInput) {
          this.setState({
            activeModal: Modals.enterHashModal,
          });
        }
      }
    }

    this.player.isGyroscopeEnabled()
      .then(val => {
        this.setState({ isGyroscopeEnabled: val })
      });
  }
  componentDidUpdate() {
    if (!this.state.initialized && this.props.presentation && this.state.clientNameSet) {
      const { viewing } = this.props;
      const hash = viewing && viewing.hash;
      this._initViewing(hash, this.props.presentation);
      this.setState({ initialized: true });
    }
  }
  componentWillUnmount() {
    this.props.updateUi({footerVisible: true});
    window.removeEventListener("fullscreenchange", this.onFullscreenChange);
    window.removeEventListener("mozfullscreenchange", this.onFullscreenChange);
    window.removeEventListener("webkitfullscreenchange", this.onFullscreenChange);
    this.props.clearPresentation();
    this.timeoutId && window.clearTimeout(this.timeoutId);
    this.player.enableRotateScene();
    this.player.unload();
    this.viewingClient.disconnect();
    this.props.navBar.setContextLinks();
  }
  onTogglePresentationDashboard(open) {
    this.setState({
      presentationDashboardOpen: open,
    });
  }
  enableGyroscope() {
    this.player.enableGyroscope()
      .then(() => this.setState({ isGyroscopeEnabled: true }));
  }
  disableGyroscope() {
    this.player.disableGyroscope()
      .then(() => this.setState({ isGyroscopeEnabled: false }));
  }
  toggleFullscreen() {
    function requestFullscreen() {
      let elem = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    }

    function exitFullscreen() {
      if(document.exitFullscreen) {
        document.exitFullscreen();
      } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if(document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }

    if (this.state.fullscreenActive) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }
  }
  handleMicClick() {
    if (this.viewingClient) {
      this.viewingClient.toggleMic();
      this.setState({
        localMute: !this.state.localMute,
      });
    }
  }
  handleVolumeClick() {
    if(this.viewingClient) {
      this.viewingClient.toggleSpeaker();
      this.setState({
        remoteMute: !this.state.remoteMute,
      });
    }
  }
  onFullscreenChange() {
    this.setState({
      fullscreenActive: !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement)
    });
  }
  toggleVR() {
    if (this.player) {
      this.props.player.isVRCompatible().then(() => {
        this.props.player.toggleVRMode();
      },this.toggleQRModal);
    } else {
      this.toggleQRModal();
    }
  }
  toggleQRModal() {
    this.setState({
      VRModalOpened: !this.state.VRModalOpened
    });
  }
  onHashSubmit(event) {
    //Validate field
    let hashInput = this.state.hashInput,
      hashValid = hashInput && hashInput.length === 6;

    if (hashValid) {
      this.props.fetchViewing({hash: this.state.hashInput});
    } else {
      this.setState({
        hashInputInvalid: true,
      });
    }
    event.preventDefault();
    return false;
  }
  onHashInputChange(event) {
    this.setState({
      hashInput: event.target.value,
      enterHashModalCharCounter: event.target.value.length,
    });
  }
  onNameSubmit(event) {
    event.preventDefault();
    this.viewingClient.setName(this.state.nameInput);
    this.toggleModal(null);
    this.setState({
      clientNameSet: true,
      openConnectionStatusModal: true,
    });
    return false;
  }
  onNameInputChange(event) {
    this.setState({nameInput: event.target.value});
  }
  toggleModal(modalName) {
    this.setState({
      activeModal: modalName,
    });
  }

  toggleSpaceplansModal() {
    const { spaceplansOpened } = this.state;
    this.setState(state => ({
        ...state,
        spaceplansOpened: !spaceplansOpened,
    }));
  }

  setPlanIndex(planIndex) {
    this.setState({
      currentSpaceplan: planIndex,
    })
  }
  onChangeScene(e) {
    console.log('onChangeScene',e);
    const { spaceplansOpened } = this.state;
    if(spaceplansOpened) {
      this.setState({
        spaceplansOpened: false,
      })
    }
  }
  toggleSpaceplansKey() {
    this.setState({ spaceplansKey: !this.state.spaceplansKey })
  }
  detectMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  isVRAviable() {
    this.player && this.player.isVRCompatible().then(() => {
      if (this.detectMobile()) {
        this.setState({ vrAviable: false });
      } else {
        this.setState({ vrAviable: true });
      }
    }, () => this.setState({ vrAviable: false }));
  }
  render() {
    const {
      hashInputInvalid,
      enterHashModalCharCounter,
      changeSceneWithPinEnabled,
      fullscreenActive,
      activeModal,
      currentSpaceplan,
      spaceplansOpened,
      isGyroscopeEnabled,
      presentingMode,
      audioConnected,
      hostConnected,
      connectionStatus,
      presentationDashboardOpen,
      presentationDashboardVisibe,
      changeSceneWithScenesNavEnabled,
      changeSceneWithArrowsEnabled,
    } = this.state;
    const { t, presentation, viewing } = this.props,
      hashInputClassName = hashInputInvalid ? 'error' : '';

    const { creator } = viewing;
    let brand = creator && !!creator.brand;
    if (brand) {
      this.props.navBar.hide();
    }

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    let contentTitle = presentation && presentation.name,
      menuButtonVisible = false,
      shareUrl = viewing && `${location.protocol}//${location.host}/v/${viewing.hash}`,
      backgroundVisible = !hostConnected,
      enterHashModalOpen = activeModal === Modals.enterHashModal,
      enterNameModalOpen = activeModal === Modals.enterNameModal,
      errorModalOpen = activeModal === Modals.errorModal,
      iconMic, iconVolume, tipText, tipColor, submitButtonDisabled,
      viewingControlsClass = Classnames({
        'viewing-controls': true,
        'viewing-controls--open': presentationDashboardOpen,
      });

    tipColor =  enterHashModalCharCounter === 0 || enterHashModalCharCounter > 5 ? "initial" : "error";
    submitButtonDisabled = enterHashModalCharCounter < 6 || enterHashModalCharCounter > 6;

    if (presentation && ((presentation.spaceplans &&
        presentation.spaceplans.length ) || (
        presentation.menuScene &&
        presentation.menuScene.sceneId
      ))) {
      menuButtonVisible = true;
    }

    if(enterHashModalCharCounter === 0) {
      tipText = t('viewing.enterHashModal.tip');
    } else if (enterHashModalCharCounter > 0 && enterHashModalCharCounter < 6) {
      tipText = t('viewing.enterHashModal.validation');
    } else {
      tipText = <span>&nbsp;&nbsp;</span>;
    }

    return (
      <div className={"viewing"}>
        {!backgroundVisible && (
          <React.Fragment>
            {presentationDashboardVisibe && <PresentationDashboard
              minimal={true}
              presentation={presentation}
              vrButtonVisible={false}
              fsButtonVisible={false}
              autoplayButtonVisible={false}
              menuButtonVisible
              controlsWhite
              onToggle={this.onTogglePresentationDashboard}
              changeSceneWithPinEnabled={changeSceneWithPinEnabled}
              changeSceneWithArrowsEnabled={!changeSceneWithArrowsEnabled}
            >
              <ScenesNav presentation={presentation} changeSceneWithScenesNavEnabled={!changeSceneWithScenesNavEnabled} />
            </PresentationDashboard>}
            <div className={Classnames(viewingControlsClass, this.detectMobile && "mobile-height")}>
              <div className="viewing-controls__block">
                <IconButton
                  className="info-icon"
                  onClick={() => this.setState({ openConnectionStatusModal: !this.state.openConnectionStatusModal })}
                >
                  <IconInfo className="tool-icon" width={40} height={40} />
                </IconButton>
                <div className="viewing-controls__participants">
                  <div className="status connected"></div>
                  <span>{creator ? creator.displayName : ''}</span>
                </div>
              </div>
              <div className="viewing-controls__block">
                <div className="viewing-controls__participants" style={{minWidth: 187}}>
                  {presentingMode !== 'guide' ? (
                    <span style={{ color: '#729a45' }}>{t('viewing.messages.youAreInControl')}</span>
                  ) : (
                    <span style={{ color: '#ca872f' }}>{t('viewing.messages.hostIsInControl')}</span>
                  )}
                </div>
              </div>
              <div className="viewing-controls__block">
                <IconButton
                  target="_blank"
                  secondary={true}
                  disabled={!hostConnected}
                >
                  {
                    audioConnected ?
                      <IconCallEnd className="tool-icon disabled"/>:
                      <IconCall className="tool-icon disabled" />
                  }
                </IconButton>
                <IconButton
                  target="_blank"
                  secondary={true}
                  disabled={!audioConnected}
                  onClick={this.handleMicClick}
                >
                  {
                    this.state.localMute ?
                      <IconMicOff className="tool-icon"/> :
                      <IconMic className={`tool-icon ${!audioConnected ? 'disabled': ''}`}/>
                  }
                </IconButton>
                <IconButton
                  target="_blank"
                  disabled={!audioConnected}
                  secondary={true}
                  onClick={this.handleVolumeClick}
                >
                  {
                    this.state.remoteMute ?
                      <IconVolumeOff className="tool-icon"/> :
                      <IconVolumeUp className={`tool-icon ${!audioConnected ? 'disabled': ''}`}/>
                  }
                </IconButton>
              </div>
            </div>
          </React.Fragment>
        )}
        <VRModal opened={this.state.VRModalOpened} requestClose={this.toggleQRModal} shareUrl={shareUrl} />
        <Modal open={enterHashModalOpen} className="viewing-modal">
          {brand && creator ? (
            <div className="modal-title">{t('viewing.enterHashModal.titleBrand')} {creator.displayName} {t('viewing.enterHashModal.titleBrand2')}</div>
          ) : (
            <div className="modal-title">{t('viewing.enterHashModal.title')}</div>
          )}
          <div className="modal-content">
            <form onSubmit={this.onHashSubmit}>
              <label>{t('viewing.enterHashModal.label')}</label>
              <input
                type="text"
                className={hashInputClassName}
                value={this.state.hashInput}
                maxLength="6"
                name="hash"
                onChange={this.onHashInputChange}
                placeholder="######" />
                <Typography className="tip" color={tipColor}>
                            {tipText}
                </Typography>
              <input type="submit" disabled={submitButtonDisabled} value={t('common.next')} className="cta-button" />
            </form>
          </div>
        </Modal>
        <Modal open={enterNameModalOpen} className="viewing-modal">
          {brand && creator ? (
            <div className="modal-title">{t('viewing.enterHashModal.titleBrand')} {creator.displayName} {t('viewing.enterHashModal.titleBrand2')}</div>
          ) : (
            <div className="modal-title">{t('viewing.enterHashModal.title')}</div>
          )}
          <div className="modal-content">
            <form onSubmit={this.onNameSubmit}>
              <label style={{ paddingRight: 55 }}>{t('viewing.enterNameModal.label')}</label>
              <input
                type="text"
                name="name"
                onChange={this.onNameInputChange}
                placeholder={t('viewing.enterNameModal.placeholder')}
              />
              <input type="submit" value={t('common.join')} className="cta-button" />
            </form>
            <div style={{ fontSize: 12, marginBottom: 16, textTransform: 'uppercase', cursor: 'pointer'}} onClick={this.onNameSubmit}>{t('presentation.connectionStatusModal.skip')}</div>
          </div>
        </Modal>
        <Modal open={errorModalOpen} className="viewing-modal">
          {brand && creator ? (
            <div className="modal-title">{t('viewing.enterHashModal.titleBrand')} {creator.displayName} {t('viewing.enterHashModal.titleBrand2')}</div>
          ) : (
            <div className="modal-title">{t('viewing.enterHashModal.title')}</div>
          )}
          <div className="modal-content">
            <form onSubmit={(e)=> {this.toggleModal(Modals.enterHashModal); e.preventDefault();return false;}}>
              <div className="input-wrapper">
                <div className="error">{t('viewing.errorModal.content1')}</div>
                <div>{t('viewing.errorModal.content2')}</div>
              </div>
              <input type="submit" value={t('common.close')} className="cta-button" />
            </form>
          </div>
        </Modal>
        <ConnectionStatusModal
          status={connectionStatus}
          open={this.state.openConnectionStatusModal}
          onClose={() => this.setState({ openConnectionStatusModal: false })}
          creator={creator}
          isMobile={this.state.isMobile}
          player={this.player}
          startVRMode={() => this.player.toggleVRMode()}
        />
        {backgroundVisible && <div className="background" />}
        {spaceplansOpened &&
        <SpaceplansModal
          key={this.state.spaceplansKey}
          refreshComponent={() => {
            this.toggleSpaceplansKey();
          }}
          player={this.player}
          changeSceneWithPinEnabled={changeSceneWithPinEnabled}
          toggleSpaceplansModal={this.toggleSpaceplansModal}
          currentSpaceplan={currentSpaceplan}
          setPlanIndex={this.setPlanIndex}
          presentation={presentation} />
        }
      </div>
    );
  }
}

const {
  getPresentation,
  isLoading,
  isFound,
  getViewing,
} = presentationSelectors;

const {
  fetchPresentation,
  clearPresentation,
  fetchViewing,
} = presentationActions;

const {
  updateUi,
} = uiActions;

const mapStateToProps = state => ({
  presentation: getPresentation(state),
  isLoading: isLoading(state),
  isFound: isFound(state),
  viewing: getViewing(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchPresentation,
    clearPresentation,
    updateUi,
    fetchViewing,
  }, dispatch),
});

function ViewingNavBar(props) {
  return <NavBarContext.Consumer>
    {navBar =>
      navBar ? <Viewing
        {...props}
        navBar={navBar}
      /> : <div/>}
  </NavBarContext.Consumer>;
}

function PlayerViewing(props) {
  return (
    <PlayerContext.Consumer>
      {player => (
        <ViewingNavBar
          {...props}
          player={player.player}
        />
      )}
    </PlayerContext.Consumer>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(translate("translations")(WithDependency(PlayerViewing)));
