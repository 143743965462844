import React, { Component } from 'react';
import { Api } from 'api';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { compose } from 'redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import Modal from 'components/modal/index.jsx';
import AssetHelper, { ImageVariants } from 'AssetHelper';

const styles = theme => ({
  title: {
    paddingRight: '50px !important',
  },
  info: {
    color: "#dba101",
    marginBottom: 16,
  },
  success: {
    color: "#0e7e3b",
  },
  status: {
    marginTop: 20,
  },
  buisnessCard: {
    backgroundColor: '#f2f2f2',
    margin: '20px 0',
    padding: '20px 0',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')] : {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  avatar: {
    borderRadius: 40,
    width: 80,
    height: 80,
  },
  ownerData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 16,
  },
  ownerName: {
    fontSize: 24,
    color: 'black',
    marginBottom: 9,
    textAlign: 'left'
  },
  flex: {
    display: 'flex',
  },
  icon: {
    marginRight: 8,
  },
  statusSecondary: {
    marginBottom: 20,
  },
  indexZ: {
    zIndex: '100 !important',
  },
  stayInBrowser: {
    textTransform: 'uppercase',
    color: 'grey',
    fontSize: 13,
    marginBottom: 16,
    cursor: 'pointer'
  }
});

class ConnectionStatusModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vr: false
    };

    this.isVRAviable = this.isVRAviable.bind(this);
  }
  componentWillReceiveProps() {
    this.isVRAviable();
  }
  isVRAviable() {
    this.props.player && this.props.player.isVRCompatible().then(() => {
      if (this.props.isMobile) {
        this.setState({ vr: false });
      } else {
        this.setState({ vr: true });
      }
    }, () => {
      this.setState({ vr: false });
    });
  }
  render() {
    const { t, open, onClose, creator, classes } = this.props;

    const status = this.props.status;
    const modalFixed = status === 'hostDisconnected';

    return (
      <Modal open={open} overlay onClose={onClose} className={`modal--title modal--startCoViewing ${status === 'hostDisconnected' ? '' : classes.indexZ}`} fixed={modalFixed} hideCloseButton={status === 'hostDisconnected' || status === 'hostConnected'}>
        <div className={classNames("modal-title", classes.title)}>
          {(t('viewing.welcomeModal.title'))}
        </div>
        <div className="modal-content">
          <div className={classes.status}>
            {status === 'hostDisconnected' ? (
              <span className={classes.info}>{t('viewing.connectionStatusModal.connectedAwaiting')}</span>
            ) : (
              <span className={classes.success}>{t('viewing.connectionStatusModal.hostConnected')}</span>
            )}
          </div>
          <div className={classes.buisnessCard}>
            <div className={classes.flexContainer}>
              <img
                className={classes.avatar}
                src={creator && creator.avatarHash ? AssetHelper.imagePath(creator.avatarHash, ImageVariants.THUMBNAIL) : '/static/home/viewing/ic_account_placeholder.svg'}
              />
              <div className={classes.ownerData}>
                {creator && creator.displayName && (
                  <div className={classes.ownerName}>
                    <span>{creator.displayName}</span>
                  </div>
                )}
                {creator && creator.phone && (
                  <div className={classes.flex}>
                    <PhoneIcon className={classes.icon} />
                    <span>{creator.phone}</span>
                  </div>
                )}
                {creator && creator.email &&  (
                  <div className={classes.flex}>
                    <MailIcon className={classes.icon} />
                    <span>{creator.email}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.statusSecondary}>
            {status === 'micAccessFailed' && <span className={classes.info}>{t('viewing.connectionStatusModal.voiceConnectionUnaviable')}</span>}
            {status === 'hostDisconnected' && <span>{t('viewing.connectionStatusModal.contactWithHost')}</span>}
            {status === 'hostConnected' && <span className={classes.success}>{t('viewing.connectionStatusModal.hostCanStartVoiceConnection')}</span>}
            {status === 'audioConnected' && <span className={classes.success}>{t('viewing.connectionStatusModal.voiceConnectionOn1')}<br />{t('viewing.connectionStatusModal.voiceConnectionOn2')}</span>}
          </div>
          {status === 'hostConnected' && (
            <React.Fragment><input
              type="button"
              value={this.state.vr ? t('presentation.connectionStatusModal.startVRMode') : t('presentation.connectionStatusModal.start')}
              className="cta-button"
              onClick={() => {
                if (this.state.vr) {
                  this.props.startVRMode();
                }
                onClose();
              }}
            />
            {this.state.vr ? <div className={classes.stayInBrowser} onClick={onClose}>
              {t('presentation.connectionStatusModal.stayInBrowser')}</div> : null}
            </React.Fragment>
          )}
        </div>
      </Modal>
    );
  }
}

ConnectionStatusModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clientName: PropTypes.string.isRequired,
  creator: PropTypes.shape({}).isRequired,
};

ConnectionStatusModal.defaultProps = {
};

export default compose(
  translate('translations'),
  withStyles(styles)
)(ConnectionStatusModal);
